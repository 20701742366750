"use client";

import { useAppSelector } from "@/redux/hook";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import HeaderCart from "./children/HeaderCart";
import StoreIcon from "@/components/icons/StoreIcon";
import HeaderWishlist from "./children/HeaderWishlist";
import HeaderLanguage from "./children/HeaderLanguage";
import HeaderCredentials from "./children/HeaderCredentials";
import HeaderCommunity, { CommunicationJson } from "./children/HeaderCommunity";
import { cn } from "@/utils/utils";
import LinkElement from "@/components/LinkElement";
import useStores from "@/lib/hooks/useStores";
import { useEffect } from "react";
import useCustomer from "@/lib/hooks/useCustomer";

const HeaderActions = ({ className }: { className?: string }) => {
	const { t } = useTranslationApi([
		"nationwide_stores",
		"member_card",
		"perfume_community",
		"perfume_trends",
		"nmagazine_review",
		"watch_now",
		"join",
		"discuss",
		"feedbacks_and_instore",
	]);
	const cartDetail = useAppSelector((state) => state.CartReducer.cartDetail);
	// const { data , status } = useSession();
	// const user = data?.user.user;
	const { data : user } = useCustomer()
	const { data: stores } = useStores({show_web : 1});
	//
	const communicationList: CommunicationJson[] = [
		{
			title: <>{t("perfume_community")}</>,
			action: <>{t("discuss")}</>,
			link: "https://trendy.namperfume.net/category/cau-chuyen-nuoc-hoa/",
			image:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1118-429891711090943-1711090943.png",
		},
		{
			title: <>{t("perfume_trends")}</>,
			action: <>{t("join")}</>,
			link: "https://trendy.namperfume.net/category/xu-huong/",
			image:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1117-228761711090939-1711090939.png",
		},
		{
			title: <>{t("nmagazine_review")}</>,
			action: <>{t("watch_now")}</>,
			link: "https://trendy.namperfume.net/category/goc-review/",
			image:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1116-796041711090935-1711090935.png",
		},
		{
			title: <>{t("feedbacks_and_instore")}</>,
			action: <>{t("watch_now")}</>,
			link: "https://trendy.namperfume.net/",
			image:
				"https://ldevasset.namefragrance.vn/uploads/filecloud/1/2024/March/1115-639341711090931-1711090931.png",
		},
	];
	//

	useEffect(() => {}, []);

	return (
		<div
			id="shop_action"
			className={cn(
				"flex items-center h-6 divide-x divide-gray-200",
				className
			)}>
			<div className="flex items-center justify-center pr-[30px] gap-1">
				<StoreIcon className=" flex-shrink-0" />
				<LinkElement
					href={"/pages/he-thong-cua-hang"}
					className="text-base lowercase leading-[17px] text-gray-500 transition-all hover:text-red-500">
					{stores?.total} {t("nationwide_stores")}
				</LinkElement>
			</div>
			<div className="relative leading-[20px] px-[30px] h-full">
				<HeaderCommunity communications={communicationList} />
			</div>
			<div className="relative px-[30px] h-full">
				<HeaderCredentials />
			</div>
			{/* {user && user.id ? null : (
				<LinkElement
					href={"/pages/about-membership"}
					className="text-base text-red-500 leading-[17px] px-4 h-full place-content-center">
					{t("member_card")}
				</LinkElement>
			)} */}
			<div className="pl-[30px] flex-shrink-0 flex items-center justify-between gap-[18px] h-full">
				<HeaderWishlist />
				<HeaderCart cartDetail={cartDetail} />
				{/* <HeaderLanguage /> */}
			</div>
		</div>
	);
};

export default HeaderActions;
